import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Btn, Title, Copy, Wrapper } from "../components/styled"

const NotFoundPage = () => (
  <Layout page="non">
    <SEO title="404: Not found" />
    <Wrapper>
      <Title className="non">Page Not Found</Title>
    </Wrapper>
    <Wrapper>
      <Copy>Sorry, you've hit a page that could not be found.</Copy>
    </Wrapper>
    <Wrapper>
      <Btn href="/" className="non">Return Home</Btn>
      </Wrapper>
  </Layout>
)

export default NotFoundPage
